import { roles } from "../../configuration/variables";
import ProjectService from "../../services/ProjectService";
import ProjectApprove from '../../views/ProjectApprove';
import ProjectCancel from '../../views/ProjectCancel';
import ProjectCreate from '../../views/ProjectCreate';
import ProjectEdit from '../../views/ProjectEdit';
import ProjectIndex from '../../views/ProjectIndex';
import ProjectNotifications from '../../views/ProjectNotifications';
import ProjectReopen from '../../views/ProjectReopen';
import ProjectShare from '../../views/ProjectShare';
import ProjectShareInvite from '../../views/ProjectShareInvite';
import ProjectShareSharedWith from '../../views/ProjectShareSharedWith';
import ProjectShareSharedWithDelete from '../../views/ProjectShareSharedWithDelete';
import ProjectShareSharedWithTransfer from '../../views/ProjectShareSharedWithTransfer';
import ProjectShow from '../../views/ProjectShow';
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import ModelBoundView from "../utils/ModelBoundView";

export default [
    {
        name: 'projects.index',
        path: '/projects/:status?',
        component: ProjectIndex,
        children: [
            {
                name: 'projects.create',
                path: 'create',
                component: ProjectCreate,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN, true),
                    ],
                    forbiddenRoute: { name: 'projects.index', params: { status: 1 } },
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                ({ to, next }) => {
                    if (!to.params.status) {
                        return next({ name: 'projects.index', params: { status: 1 } });
                    }

                    return next();
                },
            ],
            forbiddenRoute: { name: 'projects.index', params: { status: 1 } },
        },
    },
    {
        name: 'projects.show',
        path: '/projects/:projectId/show',
        component: ModelBoundView(new ProjectService, ProjectShow),
        children: [
            {
                name: 'projects.approve',
                path: '/projects/:projectId/approve',
                component: ModelBoundView(new ProjectService, ProjectApprove),
                meta: {
                    middleware: [auth, role(roles.OU_APPROVE_QUOTATION, true)],
                },
            },
            {
                name: 'projects.edit',
                path: '/projects/:projectId/edit',
                component: ModelBoundView(new ProjectService, ProjectEdit),
                meta: {
                    middleware: [auth],
                },
            },
            {
                name: 'projects.cancel',
                path: '/projects/:projectId/cancel',
                component: ModelBoundView(new ProjectService, ProjectCancel),
                meta: {
                    middleware: [auth],
                },
            },
            {
                name: 'projects.reopen',
                path: '/projects/:projectId/reopen',
                component: ModelBoundView(new ProjectService, ProjectReopen),
                meta: {
                    middleware: [auth],
                },
            },
            {
                name: 'projects.notifications',
                path: '/projects/:projectId/notifications',
                component: ModelBoundView(new ProjectService, ProjectNotifications),
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                },
            },
            {
                name: 'projects.share',
                path: 'share',
                component: ModelBoundView(new ProjectService, ProjectShare),
                children: [
                    {
                        name: 'projects.share.invite',
                        path: 'invite',
                        component: ProjectShareInvite,
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                        },
                    },
                    {
                        name: 'projects.share.shared-with',
                        path: 'shared-with',
                        component: ProjectShareSharedWith,
                        children: [
                            {
                                name: 'projects.share.shared-with.transfer',
                                path: ':user/transfer',
                                component: ProjectShareSharedWithTransfer,
                                meta: {
                                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                                },
                            },
                            {
                                name: 'projects.share.shared-with.delete',
                                path: ':user/delete',
                                component: ProjectShareSharedWithDelete,
                                meta: {
                                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                                },
                            },
                        ],
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                        },
                    },
                ],
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN, true),
                        ({ to, next }) => {
                            if (to.name === 'projects.share') {
                                return next({
                                    name: 'projects.share.invite',
                                    params: to.params,
                                    query: to.query,
                                });
                            }
                            return next();
                        },
                    ],
                },
            },
        ],
        meta: {
            middleware: [auth],
        },
    },
]
