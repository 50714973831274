<template>
    <transition name="fade">
        <div :key="$route.params.category"
             class="laths-container">
            <div class="header">
                <h1>{{ ucfirst($t("laths.cavity_bars")) }}</h1>
            </div>
            <div class="tabs">
                <router-link :to="{ name: $route.name, params: { category: 'edge-rebates' } }"
                             class="tab-item">
                    {{ ucfirst($t("laths.edge_rebates")) }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { category: 'lath-widths' } }"
                             class="tab-item">
                    {{ ucfirst($t("laths.widths")) }}
                </router-link>
                <router-link :to="{ name: $route.name, params: { category: 'by-width' } }"
                             class="tab-item">
                    {{ ucfirst($t("laths.cavity_lath_width")) }}
                </router-link>

            </div>
            <div class="search-container">
                <div class="searchbar">
                    <img src="/images/icons/search-icon.svg"
                         title="search-icon">
                    <input :placeholder="ucfirst($t('global.search'))"
                           :value="search"
                           name="search"
                           v-on:input="setSearch" />
                </div>

                <router-link :to="{ name: 'laths.create', query: $route.query, params: { category: 'edge-rebates' }}"
                             class="btn primary"
                             v-if="this.$route.params.category === 'edge-rebates'">
                    {{ ucfirst($t("laths.new_edge_rebates")) }}
                </router-link>

                <router-link
                        :to="{ name: 'laths.create.widths', query: $route.query, params: { category: 'lath-widths' }}"
                        class="btn primary"
                        v-if="this.$route.params.category === 'lath-widths'">
                    {{ ucfirst($t("laths.new_widths")) }}
                </router-link>
                <router-link :to="{ name: 'laths.create.widths', query: $route.query, params: { category: 'by-width' }}"
                             class="btn primary"
                             v-if="this.$route.params.category === 'by-width'">
                    {{ ucfirst($t("laths.new_cavity_lath_width")) }}
                </router-link>

            </div>

            <div class="table">
                <div class="row header">
                    <div class="column pointer"
                         v-on:click="setOrderBy('name')">
                        {{ ucfirst($t("laths.name")) }}
                    </div>
                </div>
                <transition-group name="list">
                    <router-link :key="lath_width.id"
                                 :to="{ name: 'laths.edit.widths', params: { category: 'lath-widths', lathWidthId: lath_width.id } }"
                                 class="row"
                                 v-for="lath_width in lath_widths">
                        <div class="column">
                            <span>
                                {{ lath_width.name }}
                            </span>
                        </div>
                    </router-link>
                </transition-group>
                <infinite-loading :distance="400"
                                  @infinite="infiniteHandler"
                                  ref="infiniteLoading"
                                  spinner="spiral">
                    <div class="no-results"
                         slot="no-more">{{ ucfirst($t("laths.no_more")) }}
                    </div>
                </infinite-loading>
                <transition mode="out-in"
                            name="fade">
                    <router-view :key="$route.fullPath" />
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
    import debounce from 'lodash/debounce';

    export default {
        name: 'lathsIndex',
        data() {
            return {
                lath_widths: [],
                search: this.$route.query.search || null,
                page: 1,
                maxPage: 1,
                orderBy: {
                    field: 'name',
                    sequence: 'DESC',
                },
            };
        },
        methods: {
            infiniteHandler($state) {
                let url = this.$route.params.category
                    + '?page=' + this.page
                    + '&order_field=' + this.orderBy.field
                    + '&order_sequence=' + this.orderBy.sequence;

                if (!!this.$route.query.search) {
                    url = url + '&search=' + this.$route.query.search;
                }

                if (this.page <= this.maxPage) {
                    this.$http.get(url)
                        .then((response) => {
                            let data = response.data;
                            this.lath_widths = [...this.lath_widths, ...data.data];
                            $state.loaded();
                        })
                        .catch(function (error) {
                        });

                    this.page = this.page + 1;
                } else {
                    $state.complete();
                }
            },
            setOrderBy(field) {
                if (field === this.orderBy.field) {
                    this.orderBy.sequence = this.orderBy.sequence === 'DESC' ? 'ASC' : 'DESC';
                } else {
                    this.orderBy.field = field;
                    this.orderBy.sequence = 'DESC';
                }

                this.$router.replace({
                    ...this.$route,
                    query: {
                        category: this.$route.query.category,
                        search: this.$route.query.search,
                        orderField: this.orderBy.field,
                        orderSequence: this.orderBy.sequence,
                    },
                });
            },
            setSearch: debounce(function (e) {
                this.search = e.target.value;

                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        search: this.search,
                        orderField: this.$route.query.orderField,
                        orderSequence: this.$route.query.orderSequence,
                    },
                });
            }, 500),
        },
        beforeRouteUpdate(to, from, next) {
            this.lath_widths = [];
            this.page = 1;
            this.maxPage = 1;
            this.search = null;
            this.orderBy = {
                field: 'created_at',
                sequence: 'DESC',
            };

            if (to.params.category === from.params.category) {
                this.search = to.query.search;
                this.orderBy = {
                    field: to.query.orderField,
                    sequence: to.query.orderSequence,
                };
            }

            setTimeout(() => {
                this.$refs.infiniteLoading.stateChanger.reset();
            }, 500);

            return next();
        },
        mounted() {
            this.search = this.$route.query.search || null;
        },
    };
</script>


<style scoped>

</style>