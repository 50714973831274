export default function boundBy(service, vueConfig = {}) {
    const entity = service.getEntity();

    return {
        ...vueConfig,
        data() {
            const data = vueConfig.data || (() => ({}));

            return {
                ...(data()),
                [entity]: {},
            };
        },
        methods: {
            ...vueConfig.methods,
            _setEntity(data) {
                this.$set(this, entity, data);
            },
        },
        beforeMount() {
            service.find(this.$route.params[`${entity}Id`])
                .then((data) => {
                    this._setEntity(data);

                    if (!this.empty(vueConfig.onModelLoaded)) {
                        const onModelLoaded = vueConfig.onModelLoaded.bind(this);

                        onModelLoaded(data, entity);
                    }
                });

            if (!this.empty(vueConfig.beforeMount)) {
                const beforeMount = vueConfig.beforeMount.bind(this);

                beforeMount();
            }
        },
    };
}
