<template>
    <div class="overview">
        <div class="header">
            <h1>
                {{ $t('Frame group') }}
            </h1>
        </div>
        <div class="tabs">
            <router-link :to="{ name: 'window-frame-groups.edit.general' }"
                         class="tab-item">
                {{ $t('General') }}
            </router-link>
            <router-link :to="{ name: 'window-frame-groups.edit.frames' }"
                         class="tab-item">
                {{ $t('Frames') }}
            </router-link>
        </div>

        <router-view v-if="!empty(frameGroup.id)" />
    </div>
</template>

<script>
    export default {
        name: "WindowFrameGroupEdit",
    };
</script>
