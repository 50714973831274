<template>
    <transition>
        <div class="navbar-container">
            <div class="logo">
                <img class="big"
                     src="/images/nav-logo.svg"
                     title="logo">
                <img class="small"
                     src="/images/nav-logo-small.svg"
                     title="logo" />
            </div>
            <router-link :to="{ name: 'projects.index', params: { status: 1 } }"
                         class="navbar-item">
                <img src="/images/icons/company-icon.svg"
                     title="company-icon">
                <span>
                    {{ ucfirst($t("projects.project")) }}
                </span>
            </router-link>
            <router-link :to="{ name: 'customers.index' }"
                         class="navbar-item"
                         v-auth.global-admin>
                <img src="/images/icons/customers-icon.svg"
                     title="company-icon">
                <span>
                    {{ ucfirst($t("customers.customers")) }}
                </span>
            </router-link>

            <div class="dropdown-container" v-on:click="toggleDropdown">
                <div :class="{'active' : showDropdown}" class="nav-item">
                    <img src="/images/icons/revit-icon.svg"
                         title="revit-icon">
                    <span>Revit beheer</span>
                    <img :class="{'dropped' : showDropdown}" class="dropdown-img"
                         src="/images/icons/dropdown-arrow.svg"
                         title="dropdown-arrow">
                </div>

                <div :class="{'show' : showDropdown}" class="dropdown">
                    <router-link :to="{ name: 'frames.index' }"
                                 class="dropdown-item">
                        <span>
                            {{ ucfirst($t("frames.frames")) }}
                        </span>
                    </router-link>
                    <router-link :to="{ name: 'glass.index' }"
                                 class="dropdown-item">
                        <span>
                            {{ ucfirst($t("glass.glass")) }}
                        </span>
                    </router-link>
                    <router-link :to="{ name: 'laths.index' }"
                                 class="dropdown-item">
                        <span>
                            {{ ucfirst($t("laths.laths")) }}
                        </span>
                    </router-link>
                    <router-link :to="{ name: 'upper-racks.index' }"
                                 class="dropdown-item">
                        <span>
                            {{ ucfirst($t("racks.upper.upper_racks")) }}
                        </span>
                    </router-link>
                    <router-link :to="{ name: 'under-racks.index' }"
                                 class="dropdown-item">
                        <span>
                            {{ ucfirst($t("racks.under.under_racks")) }}
                        </span>
                    </router-link>
                </div>
            </div>

            <router-link :to="{ name: 'notifications.index' }" class="navbar-item">
                <img src="/images/icons/notifications-icon.svg"
                     title="notifications-icon">
                <span>Notificaties</span>
                <transition name="fade">
                    <span class="notifications"
                          v-if="notificationAmount > 0">{{ notificationAmount }}</span>
                </transition>
            </router-link>
            <router-link :to="{ name: 'settings.index' }"
                         class="navbar-item"
                         v-auth.global-admin:invert>
                <img src="/images/icons/settings-icon.svg"
                     title="settings-icon">
                <span>Instellingen</span>
            </router-link>

            <div class="navbar-user"
                 v-on:click="toggleMenu">
                <div :class="{'show' : showMenu}"
                     class="submenu">
                    <router-link :to="{ name: 'profile.show' }"
                                 class="menu-item">
                        {{ ucfirst($t("navbar.my_profile")) }}
                    </router-link>
                    <router-link :to="{ name: 'objects.index' }"
                                 class="menu-item"
                                 v-auth.global-admin>
                        <span>
                            {{ ucfirst($t("objects.objects")) }}
                        </span>
                    </router-link>
                    <router-link :to="{ name: 'auth.logout' }"
                                 class="menu-item">
                        {{ ucfirst($t("navbar.logout")) }}
                    </router-link>
                </div>
                <img :src="user.imageUrl"
                     class="profile"
                     v-if="user.imageUrl" />
                <div class="profile with-background"
                     v-else>
                    {{ initials }}
                </div>
                <p class="name">
                    {{ user.firstName }} {{ user.middleName }} {{ user.lastName }}
                </p>
                <img class="toggle"
                     src="/images/icons/dropdown-arrows.svg">
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "NavBar",
        data() {
            return {
                showMenu: false,
                dropdownVisible: false,
            }
        },
        methods: {
            /**
             * Toggles the menu.
             */
            toggleMenu() {
                this.showMenu = !this.showMenu;
            },


            /**
             * Toggles the Revit Dropdown.
             */
            toggleDropdown() {
                this.dropdownVisible = !this.dropdownVisible;
            },
        },
        computed: {
            showDropdown() {
                return !this.empty(this.$route.params.category) || this.dropdownVisible;
            },
            initials() {
                const { user } = this;

                if (this.empty(this.user.firstName)) {
                    return '';
                }

                return user.firstName.charAt(0) + user.lastName.charAt(0)

            },
            /**
             * Get the amount of unread notifications.
             */
            notificationAmount() {
                // return this.$store.getters.getNotificationAmount
                return 0;
            }
        },
    }
</script>
