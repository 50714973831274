import LathIndex from "../../../views/LathIndex";
import LathCreateWidths from "../../../views/LathCreateWidths";
import LathEditWidths from "../../../views/LathEditWidths";
import LathDeleteWidths from "../../../views/LathDeleteWidths";
import auth from "../../middleware/_auth";
import role from "../../middleware/_role";
import { roles } from "../../../configuration/variables";
import ModelBoundView from "../../utils/ModelBoundView";
import LathWidthService from "../../../services/LathWidthService";

export default [
    {
        name: 'laths.index',
        path: '/laths/:category?',
        component: LathIndex,
        children: [
            {
                name: 'laths.create.widths',
                path: 'create',
                component: LathCreateWidths,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },
            {
                name: 'laths.edit.widths',
                path: ':lathWidthId/edit',
                component: ModelBoundView(new LathWidthService, LathEditWidths),
                meta: {
                    middleware: [
                        auth,
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },
            {
                name: 'laths.delete.widths',
                path: ':lathWidthId/delete',
                component: LathDeleteWidths,
                meta: {
                    middleware: [
                        auth,
                    ],
                    forbiddenRoute: { name: 'frames.index' },
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => {
                    if (!to.params.category) {
                        return next({ name: 'laths.index', params: { category: 'edge-rebates' } });
                    }

                    return next();
                },

            ],
            forbiddenRoute: { name: 'laths.index' },
        },
    },
]