import { render, staticRenderFns } from "./UpperRackEditOuterPlates.vue?vue&type=template&id=4afd4dde&scoped=true&"
import script from "./UpperRackEditOuterPlates.vue?vue&type=script&lang=js&"
export * from "./UpperRackEditOuterPlates.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4afd4dde",
  null
  
)

export default component.exports