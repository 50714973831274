<template>
    <form-panel @overlay-clicked="close" @submit="submit" ref="panel">
        <template v-slot:header>
            <h1>
                {{ ucfirst($t('racks.upper.raster_lengths')) }}
            </h1>
            <div class="edit">
                <img alt="edit"
                     src="/images/icons/dots-icon.svg" />
                <div class="drop">
                    <router-link
                            :to="{ name: 'upper-racks.delete.raster-lengths', params: $route.params }"
                            tag="button">
                        {{ ucfirst($t("racks.upper.delete_raster_lengths")) }}
                    </router-link>
                </div>
            </div>
            <div class="name">
                <h2>
                    {{upperRackRasterLength.name}}
                </h2>
            </div>
        </template>
        <template v-slot:form>
            <h2>
                {{ ucfirst($t('settings.general_data')) }}
            </h2>
            <div class="form-group">
                <label for="name">
                    {{ ucfirst($t("racks.upper.name")) }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="upperRackRasterLength.name" />
                <label for="value">
                    {{ ucfirst($t("racks.upper.value")) }}
                </label>
                <input id="value"
                       name="value"
                       ref="value"
                       type="text"
                       v-model="upperRackRasterLength.value" />
            </div>

            <button class="primary">
                {{ ucfirst($t('global.save_data')) }}
            </button>
        </template>
    </form-panel>
</template>

<script>
    import moment from "moment";
    import BasePanel from "../components/BasePanel";
    import FormPanel from "../components/FormPanel";

    export default {
        name: "UpperRackEditRasterLengths",
        components: { BasePanel, FormPanel },
        methods: {
            close() {
                this.$router.replace({
                    name: 'upper-racks.index',
                    params: this.$route.params,
                });
            },
            submit() {
                this.$http.post(`${this.$route.params.category}/${this.$route.params.upperRackRasterLengthId}/edit`, this.upperRackRasterLength)
                    .then(() => {
                        this.$router.replace({
                            name: 'upper-racks.index',
                            params: this.$route.params,
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        beforeMount() {
            this.$http.get('')
                .then((response) => {
                    this.upper_rack_rules = response.data.data;
                });
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    }
</script>

<style scoped>

</style>