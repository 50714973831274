<template>
    <base-panel @overlay-clicked="close"
                class="sidebar-container"
                panel-class="sidebar"
                ref="panel">
        <div class="header">
            <h1>
                {{ ucfirst($t('glass.new_types')) }}
            </h1>
        </div>
        <form class="body"
              ref="employeeForm"
              v-on:submit.prevent="submit">
            <h2>
                {{ ucfirst($t('settings.general_data')) }}
            </h2>
            <div class="form-group">
                <label for="name">
                    {{ ucfirst($t("glass.name")) }}
                </label>
                <input id="name"
                       name="name"
                       ref="name"
                       type="text"
                       v-model="formData.name" />
                <label for="value">
                    {{ ucfirst($t("glass.value")) }}
                </label>
                <input id="value"
                       name="value"
                       ref="value"
                       type="text"
                       v-model="formData.value" />
            </div>
            <button class="primary">
                {{ ucfirst($t('glass.new_applications')) }}
            </button>
        </form>
    </base-panel>
</template>

<script>
    import moment from "moment";
    import BasePanel from "../components/BasePanel";

    export default {
        name: "glassCreateApplications",
        components: { BasePanel },
        data() {
            return {
                glassApplications: [],
                formData: {},
            };
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'glass.index',
                    params: this.$route.params,
                });
            },
            submit() {
                const formData = this.formData;

                this.$http.post('/glass-applications/create', formData)
                    .then(() => {
                        this.$router.replace({
                            name: 'glass.index',
                            params: this.$route.params,
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        beforeMount() {
            this.$http.get('/glass-applications')
                .then((response) => {
                    this.glassApplications = response.data.data;
                });
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    }
</script>
